import styles from "./Loader.module.scss";
import { useState, useEffect, useRef } from "react";

const Loader = () => {
  const [lottie, setLottie] = useState();
  const lottieRef = useRef(null);

  useEffect(() => {
    import("lottie-web").then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && lottieRef.current) {
      const animation = lottie.loadAnimation({
        container: lottieRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: require("../..//public/lottie/loading.json"),
      });

      return () => animation.destroy();
    }
  }, [lottie]);

  return (
    <div className={styles.screen}>
      <div className="w-96 align-self-center m-auto align-middle" ref={lottieRef} />
    </div>
  );
};

export default Loader;
