
// import SoundBar from "./SoundBar/SoundBar";
// import SoundButton from "./SoundButton/SoundButton";

const Header = ({ children, toggleCursor, isCursor, isDesktop }) => {
  return (
    <nav className="w-full fixed top-0 py-8 z-50 select-none bg-gradient-to-b from-gray-dark-5 shadow-gray-dark-5 transition-all duration-300">
      <div className="flex justify-between section-container">
        <a href="#home" className="link" type={!isCursor ? "x" : "checkbox"}>
          <img src="/logo.svg" alt="Logo" width={64} height={64} />
        </a>

        <div className="outer-menu relative">
          {isDesktop && (
            <a
              className="link"
              onClick={() => toggleCursor()}
              type={!isCursor ? "x" : "checkbox"}
            >
              <img
                src="/mouse.svg"
                alt="mouse"
                width={24}
                height={24}
                style={{
                  marginRight: 48,
                }}
              />
            </a>
          )}
          <input
            aria-labelledby="menu"
            className="checkbox-toggle link absolute top-0 right-0 w-6 h-6 opacity-0"
            type={"checkbox"}
            aria-label="menu"
          />
          <div className="hamburger absolute top-0 right-0 w-6 h-6 flex items-center justify-center">
            <div className="relative flex-none w-full bg-white duration-300 flex items-center justify-center"></div>
          </div>
          {children}
        </div>
      </div>
    </nav>
  );
};

export default Header;
