export const METADATA = {
  author: "Sıtkı Can ERZURUM",
  title: "Sıtkı Can ERZURUM | Portfolio",
  description:
    "Sıtkı Can ERZURUM from Turkey is a React Native Mobile Application Developer with an interest in building beautiful and functional mobile applications.",
  siteUrl: "https://www.canerzurum.com.tr/",
  keywords: [
    "Sıtkı Can ERZURUM",
    "Frontend Developer",
    "Web Developer",
    "React Native Developer",
    "Software Developer",
    "Software Engineer",
    "Portfolio",
    "Devfolio",
    "Folio",
  ].join(", "),
  language: "English",
};

export const MENULINKS = [
  {
    name: "Home",
    ref: "home",
  },
  {
    name: "Skills",
    ref: "skills",
  },
  {
    name: "Projects",
    ref: "projects",
  },
];

export const TYPED_STRINGS = [
  "A practical React Native Mobile App Developer",
  "I build things for mobile applications",
  "I create aesthetic and modern mobile apps",
];

export const SOCIAL_LINKS = [
  {
    name: "mail",
    url: "mailto: can.erzrm@icloud.com",
  },
  {
    name: "linkedin",
    url: "https://www.linkedin.com/in/can-erzurum/",
  },
  {
    name: "github",
    url: "https://github.com/blackhope58",
  },
  {
    name: "instagram",
    url: "https://www.instagram.com/can.erzrm/",
  },
];

export const SKILLS = {
  languagesAndTools: [
    "html",
    "css",
    "javascript",
    "typescript",
    "firebase",
    "figma",
    "photoshop",
    "illustrator",
  ],
  librariesAndFrameworks: ["react", "redux", "navigation"],
  stores: ["google", "apple"],
  other: ["github", "jira"],
};

export const PROJECTS = [
  {
    name: "Wezzomart",
    subtitle: "E-Commerce App",
    image: "/projects/wezzomart.jpg",
    blurImage: "/projects/wezzomart.jpg",
    description:
      "Wezzomart is an e-commerce mobile application that sells to iraq.",
    gradient: ["#FFA62E", "#EA4D2C"],
    url: "https://play.google.com/store/apps/details?id=com.rn.wezzomart",
    tech: ["react", "graphql", "firebase", "navigation", "typescript"],
  },
  {
    name: "Beeme",
    subtitle: "Social Media App",
    image: "/projects/beeme.jpg",
    blurImage: "/projects/beeme.jpg",
    description: "Beeme is a social media application similar to Instagram.",
    gradient: ["#F14658", "#DC2537"],
    url: "https://play.google.com/store/apps/details?id=com.beeme",
    tech: ["typescript", "react", "navigation", "firebase"],
  },
  {
    name: "Tarih Quiz App",
    subtitle: "Quiz App",
    image: "/projects/quiz.jpg",
    blurImage: "/projects/blur/airbnb-blur.webp",
    description: "Tarih Quiz is a question solving application.",
    gradient: ["#000066", "#6699FF"],
    url: "https://play.google.com/store/apps/details?id=com.tarihbilgiyarismasiquiz",
    tech: ["navigation", "nodejs", "react", "redux"],
  },
  {
    name: "Other Projects",
    image: "/projects/myproject.jpg",
    blurImage: "/projects/blur/tesla-blur.webp",
    description: "Here are some of my other projects.",
    gradient: ["#5F00CA", "#3B0356"],
    url: "https://play.google.com/store/apps/dev?id=6088266441618320497",
    tech: [],
  },
];
