import { useEffect, useRef } from "react";
import { MENULINKS, PROJECTS } from "../../constants";
import { gsap, Linear } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import ProjectTile from "../Projects/ProjectTile/ProjectTile";

const Projects = ({ isDesktop, clientHeight, isCursor }) => {
  const targetSection = useRef(null);

  return (
    <div
      style={{
        width: "90vw",
        alignSelf: "center",
      }}
      id={MENULINKS[2].ref}
    >
      <div>
        <p className="uppercase tracking-widest text-gray-light-1 seq">
          PROJECTS
        </p>
        <h1 className="text-6xl mt-2 font-medium text-gradient w-fit seq">
          My Projects
        </h1>
        <h2 className="text-[1.65rem] font-medium md:max-w-lg max-w-sm mt-2 seq">
          Crafted with passion, skill, and a touch of enchantment, here are a
          few creations I&apos;ve brought to life.{" "}
        </h2>
      </div>

      {PROJECTS.map((project, index) => (
        <div
          style={{
            borderRadius: "0 24px 0 24px",
          }}
          key={index}
        >
          <ProjectTile
            classes={
              index === PROJECTS.length - 1 ? "" : "mr-10 xs:mr-12 sm:mr-16 "
            }
            project={project}
            key={project.name}
            isCursor={isCursor}
          />
        </div>
      ))}
    </div>
  );
};

export default Projects;
