import { useEffect, useRef } from "react";
import { MENULINKS, SKILLS } from "../../constants";
import { gsap, Linear } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const Skills = () => {
  const targetSection = useRef(null);

  useEffect(() => {
    const revealTl = gsap.timeline({ defaults: { ease: Linear.easeNone } });
    revealTl.from(
      targetSection.current.querySelectorAll(".seq"),
      { opacity: 0, duration: 0.5, stagger: 0.5 },
      "<"
    );

    ScrollTrigger.create({
      trigger: targetSection.current.querySelector(".skills-wrapper"),
      start: "100px bottom",
      end: `center center`,
      animation: revealTl,
      scrub: 0,
    });
  }, [targetSection]);

  return (
    <section
      className="w-full relative select-none mt-44"
      id={MENULINKS[1].ref}
      ref={targetSection}
    >
      <div className="section-container py-16 flex flex-col justify-center">
        <img
          src="/right-pattern.svg"
          alt=""
          className="absolute hidden right-0 bottom-2/4 w-2/12 max-w-xs md:block"
          loading="lazy"
          height={700}
          width={320}
        />
        <div className="flex flex-col skills-wrapper">
          <div className="flex flex-col">
            <p className="uppercase tracking-widest text-gray-light-1 seq">
              SKILLS
            </p>
            <h1 className="text-6xl mt-2 font-medium text-gradient w-fit seq">
              My Skills
            </h1>
            <h2 className="text-[1.65rem] font-medium md:max-w-lg w-full mt-2 seq">
              I specialize in creating captivating solutions as a React Native
              developer with expertise in mobile app development.{" "}
            </h2>
          </div>
          <div className="mt-10">
            <h3 className="uppercase tracking-widest text-gray-light-2 font-medium text-base mb-4 seq">
              LANGUAGES AND TOOLS
            </h3>
            <div className="flex flex-wrap gap-6 transform-gpu seq">
              {SKILLS.languagesAndTools.map((skill, index) => (
                <div className="flex flex-col" key={index}>
                  <img
                    key={skill}
                    src={`/skills/${skill}.svg`}
                    alt={skill}
                    width={50}
                    height={50}
                  />
                  <p
                    className="uppercase tracking-widest text-gray-light-1 seq mt-2"
                    style={{ fontSize: 12, textAlign: "center" }}
                  >
                    {skill}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-10">
            <h3 className="uppercase tracking-widest text-gray-light-2 font-medium text-base mb-4 seq">
              LIBRARIES AND FRAMEWORKS
            </h3>
            <div className="flex flex-wrap gap-6 transform-gpu seq">
              {SKILLS.librariesAndFrameworks.map((skill, index) => (
                <div className="flex flex-col" key={index}>
                  <img
                    key={skill}
                    src={`/skills/${skill}.svg`}
                    alt={skill}
                    width={50}
                    height={50}
                  />
                  <p
                    className="uppercase tracking-widest text-gray-light-1 seq mt-2"
                    style={{ fontSize: 12, textAlign: "center" }}
                  >
                    {skill}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-wrap mt-10">
            <div className="mr-16 xs:mr-20 mb-6">
              <h3 className="uppercase tracking-widest text-gray-light-2 font-medium text-base mb-4 seq">
                STORES
              </h3>
              <div className="flex flex-wrap gap-6 transform-gpu seq">
                {SKILLS.stores.map((skill, index) => (
                  <div className="flex flex-col" key={index}>
                    <img
                      key={skill}
                      src={`/skills/${skill}.svg`}
                      alt={skill}
                      width={50}
                      height={50}
                    />
                    <p
                      className="uppercase tracking-widest text-gray-light-1 seq mt-2"
                      style={{ fontSize: 12, textAlign: "center" }}
                    >
                      {skill}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h3 className="uppercase tracking-widest text-gray-light-2 font-medium text-base mb-4 seq">
                Other
              </h3>
              <div className="flex flex-wrap gap-6 transform-gpu seq">
                {SKILLS.other.map((skill, index) => (
                  <div className="flex flex-col" key={index}>
                    <img
                      key={skill}
                      src={`/skills/${skill}.svg`}
                      alt={skill}
                      width={50}
                      height={50}
                    />
                    <p
                      className="uppercase tracking-widest text-gray-light-1 seq mt-2"
                      style={{ fontSize: 12, textAlign: "center" }}
                    >
                      {skill}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
