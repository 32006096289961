import { useEffect, useRef } from "react";
import { gsap, Linear } from "gsap";
import Button from "../Button/Button";
import Profiles from "../Profiles/Profiles";

const Footer = ({ isCursor }) => {
  const targetSection = useRef(null);

  useEffect(() => {
    const revealTl = gsap.timeline({ defaults: { ease: Linear.easeNone } });
    revealTl
      .to(targetSection.current, { opacity: 1, duration: 2 })
      .from(
        targetSection.current.querySelectorAll(".seq"),
        { opacity: 0, duration: 0.5, stagger: 0.5 },
        "<"
      );
  }, [targetSection]);

  return (
    <footer
      className="w-full relative select-none bg-cover"
      ref={targetSection}
    >
      <div className="w-full h-full ">
        <div className="section-container flex flex-col h-full justify-end z-10 items-center py-12">
          <h1 className="font-medium text-3xl md:text-4xl text-center seq">
            Feel free to connect on social media.
          </h1>
          <div className="text-center seq">
            <Profiles isCursor={isCursor} />
          </div>
          <div className="seq pt-4 text-center">
            <Button
              href={"https://www.linkedin.com/in/can-erzurum/"}
              classes="link"
              type="secondary"
              target="_blank"
              isCursor={isCursor}
            >
              Let&apos;s Talk
            </Button>
          </div>
        </div>
      </div>
      <style jsx global>{`
        footer {
          background-image: linear-gradient(270deg, #e38314, #ff5e00, #ff7700);
        }
      `}</style>
    </footer>
  );
};

export default Footer;
