import { useRef, useEffect } from 'react';

const ProgressIndicator = () => {
    const progress = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const totalScroll = document.body.scrollTop || document.documentElement.scrollTop;
            const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrolled = totalScroll / windowHeight;
            if (progress.current) {
                progress.current.style.transform = `scaleX(${scrolled})`;
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [progress]);

    return (
        <div className="progress w-full fixed top-0 z-50">
            <div ref={progress} className="progress-bar"></div>
        </div>
    );
}

export default ProgressIndicator;